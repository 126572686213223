function refreshServers() {
    fetch("/api/v2/servers-update").then(stream => stream.json()).then(({ error, response: servers }) => {
        if (error) {
            throw new Error("Error requesting servers: " + error);
        }
        for (const server of servers) {
            // updateServer(server)
            if (server) {
                const row = document.getElementById(`server-${server.ip}`)
                if (typeof server.mode == "string") row.children[2].textContent = server.mode;
                if (typeof server.map == "string") row.children[3].textContent = server.map;
                if (typeof server.players == "number" && typeof server.maxPlayers == "number") row.children[4].textContent = `${server.players}/${server.maxPlayers}`;
            }
        }
    })


    fetch("/api/v2/server-options").then(s => s.json()).then(({ error, response }) => {
        if (error) {
            throw new Error("Error requiresing server options" + error)
        }

        for (const key in response) {
            const select = document.querySelector(`[name=${key}]`)
            console.log(key, select),response[key]
            const firstOption = select.children[0];
            select.innerHTML = "";
            select.appendChild(firstOption)

            select.append(...response[key].map(value=>{
                const option = document.createElement("option")
                option.textContent = value;
                
                return option
            }))         

        }
    })

    setTimeout(()=>{
        requestAnimationFrame(refreshServers)
    },10000)
}

refreshServers();

function updateSort(key, dir) {

}

function updateFilter({gamemode,region,notempty,notfull,mode,map}) {

}


globalThis.updateSort = updateSort
globalThis.updateFilter = updateFilter;


